<template>
  <div class="header">
    <div class="header-basic">
      <div v-if="$route.name == 'home'" class="top-row ">
        <div class="nav-first">
        </div>

        <div class="navbar-brand"><img src="/img/tac.png" /> 宅建士一問一答アプリ</div>
        <div class="nav-second dropdown" style="z-index: 9999">
          <button class="btn " type="button" data-bs-toggle="dropdown" aria-expanded="false" ontouchstart="">
            <img src="@/assets/img/icon_setting.svg" />
          </button>
          <ul class="dropdown-menu">
            <li><button class="dropdown-item" @click="reset">初期化</button></li>

            <li style="text-align: right; padding-left: 1em; padding-right: 1em; font-size: 0.8em;">Ver. 0.6</li>
            <!-- <li><button class="dropdown-item" @click="logout">ログアウト</button></li> -->
          </ul>
        </div>

      </div>
      <div v-else class="top-row ">
        <div class="nav-first">
          <button v-if="routeState.leftBtn != '' && routeState.leftAction != null" class="btn"
            @click="routeState.leftAction" ontouchstart="">
            <img :src="'/img/' + routeState.leftBtn + '.svg'" />
          </button>
        </div>

        <div class="navbar-brand">{{ routeState.title }}</div>
        <div class="nav-second">
          <button v-if="routeState.rightBtn != '' && routeState.rightAction != null" class="btn"
            @click="routeState.rightAction" ontouchstart="">
            <img :src="'/img/' + routeState.rightBtn + '.svg'" />
          </button>
        </div>

      </div>
    </div>

  </div>
</template>
  
<script lang="ts">
import { defineComponent, inject } from 'vue';
import { DefaultHttpClient, IHttpClient } from '@/services/HttpClient';
import { DefaultBasicUtil, IBasicUtil } from '@/services/BasicUtil';
import { IDbClient, DefaultDbClient } from '@/services/DbClient';
import { DefaultRouteState, IRouteState } from '@/services/RouteState';
import Cookies from 'js-cookie';

export default defineComponent({
  name: 'Header',
  components: {
  },

  data() {
    return {
    }
  },

  setup() {
    return {
      httpClient: inject<IHttpClient>(DefaultHttpClient) as IHttpClient,
      basicUtil: inject<IBasicUtil>(DefaultBasicUtil) as IBasicUtil,
      db: inject<IDbClient>(DefaultDbClient) as IDbClient,
      routeState: inject<IRouteState>(DefaultRouteState) as IRouteState,
    };
  },
  methods: {
    logout() {
      if (!confirm("本当にログアウトしますか？")) return
      Cookies.remove("t-token")
      location.href = "/auth"
    },
    async reset() {
      if (!navigator.onLine) return alert("この操作は通信可能状態で行ってください。")
      if (!confirm("本当にすべての学習履歴を削除しますか？この操作は取り消せません。")) return
      const result = await this.db.deleteAllDb()
      if (result) {
        await this.httpClient.reset()
        location.reload()
      }
    }
  }
});
</script>

<style>
.header-basic {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 999;
}

.top-row {
  display: flex;
  border-bottom: solid 2px #AAAAAA;
  height: 56px;
  text-align: center;
  align-content: center;
  align-items: center;
}

.navbar-brand {
  flex: 1;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.navbar-brand img {
  height: 1.1em;
}

.nav-first {
  width: 60px;
  text-align: left;
}

.nav-second {
  width: 60px;
  text-align: right;
}
</style>