/* eslint-disable no-console */

import { register } from 'register-service-worker'
const cacheName = 'cache-to-keep0.9'
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {

    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated(registration) {        
      caches.keys().then(names =>
        Promise.all(
          names.map(name => 
            (name !== cacheName) ? caches.delete(name).then(response => {
                if (response) {
                  console.log('Cache cleared');
                  return true;
                } else {
                  console.log('No cache to clear');
                  return false;
                }
              }) : Promise.resolve()
          )
        )
      ).then((results) => {
        // If any of the results are true, the page should be reloaded.
        if(results.includes(true)){
          console.log('All caches are cleared!');       
          window.location.reload();
        } else {
          console.log('No updates available.')
        }
    
      }).catch(error => {
        console.error('An error occurred while clearing caches:', error);
      });
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
