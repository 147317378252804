import Dexie from "dexie";
class AppDb extends Dexie {
  public config: Dexie.Table<IConfig, number>;
  public chapter: Dexie.Table<IChapter, number>;
  public section: Dexie.Table<ISection, number>;
  public question: Dexie.Table<IQuestion, number>;
  public questionResult: Dexie.Table<IQuestionResult, number>;
  public dayData: Dexie.Table<IDayData, number>;
  public searchQuery: Dexie.Table<SearchQuery, number>;
  public result: Dexie.Table<IResult, number>;

  constructor() {
    super("AppDatabase");
    try {
      this.createStore()
    } catch {
      this.delete().then(_ => {
        this.createStore()
      })
    }

    

    this.config = this.table("config");
    this.chapter = this.table("chapter");
    this.section = this.table("section");
    this.question = this.table("question");
    this.questionResult = this.table("questionResult");
    this.dayData = this.table("dayData");
    this.searchQuery = this.table("searchQuery");
    this.result = this.table("result");
  }

  private createStore() {
    this.version(1).stores({
      config: "++id,goalCount,version",
      chapter: "++id,no,name",
      section: "++id,chapterId,no,name",
      question: "++id,chapterId,sectionId,answer,text,lecture",
      questionResult: "++id,chapterId,sectionId,questionId,allCount,correctCount,isBookmark,history,reviewHistory,historyTime",
      dayData: "++id,dateStr,count",
      searchQuery: "++id,chapterId,sectionId",
      result: "++id,questionId,isAnswer,isCorrect,choice",
    });
  }
}

interface IConfig {
  id?: number;
  goalCount: number,
  time: number
  version: number;
}

interface IChapter {
  id?: number;
  no: number;
  name: string;
}

interface ISection {
  id?: number;
  chapterId: number
  no: number;
  name: string;
}

interface IQuestion {
  id?: number;
  chapterId: number;
  sectionId: number
  no: number
  answer: number;
  text: string;
  lecture: string;
}

interface IQuestionResult {
  id?: number;
  chapterId: number;
  sectionId: number
  questionId: number
  allCount: number;
  correctCount: number;
  isBookmark: boolean;
  history: string
  reviewHistory: string
  historyTime: string
}

interface IDayData {
  id?: number;
  dateStr: string
  count: number;
}

interface IResult {
  id?: number;
  questionId: number
  isAnswer: boolean
  isCorrect: boolean
  choice: number,
}

interface QuestionSet {
  question: IQuestion
  chapter: IChapter
  section: ISection
  questionResult: IQuestionResult,
  result: IResult
}

interface SearchQuery
{
  id?: number;
  chapterId: number
  sectionId: number
}

export { AppDb, IChapter, IConfig, IQuestion, ISection, IQuestionResult, IDayData, QuestionSet, SearchQuery, IResult };