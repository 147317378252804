import { InjectionKey, reactive } from "vue";

export const DefaultRouteState: InjectionKey<IRouteState> = Symbol("route-state");
export interface IRouteState {
    isLogin: boolean
    title: string,
    leftBtn: string,
    rightBtn: string,
    leftAction: (() => void) | null
    rightAction: (() => void) | null
}

const state = reactive<IRouteState>({
    isLogin: false,
    title: "",
    leftBtn: "",
    rightBtn: "",
    leftAction: null,
    rightAction: null
});

export class RouteState implements IRouteState {
    get isLogin() {
        return state.isLogin;
    }

    set isLogin(value: boolean) {
        state.isLogin = value;
    }
    get title() {
        return state.title;
    }

    set title(value: string) {
        state.title = value;
    }

    get leftBtn() {
        return state.leftBtn;
    }

    set leftBtn(value: string) {
        state.leftBtn = value;
    }

    get rightBtn() {
        return state.rightBtn;
    }

    set rightBtn(value: string) {
        state.rightBtn = value;
    }

    get leftAction() {
        return state.leftAction;
    }

    set leftAction(value: (() => void) | null) {
        state.leftAction = value;
    }

    get rightAction() {
        return state.rightAction;
    }

    set rightAction(value: (() => void) | null) {
        state.rightAction = value;
    }
}