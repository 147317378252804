import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/AuthView.vue')
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('../views/GuideView.vue')
  },
  {
    path: '/goal-setting',
    name: 'goal-setting',
    component: () => import('../views/GoalSettingView.vue')
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/QuestionListView.vue')
  },
  {
    path: '/question/:index',
    name: 'question',
    component: () => import('../views/QuestionView.vue')
  },
  {
    path: '/review',
    name: 'review',
    component: () => import('../views/Review.vue')
  },
  {
    path: '/mypage',
    name: 'mypage',
    component: () => import('../views/MyPageView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
