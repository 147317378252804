import { ActiveLoader, useLoading } from 'vue-loading-overlay'

import { InjectionKey, Ref } from "vue";
import { IDbClient } from './DbClient';

export const DefaultBasicUtil: InjectionKey<IBasicUtil> = Symbol("util");
export interface IBasicUtil {
  
  /**
   * loading表示
   * component: VueComponent(this)
   * isFullScreen: 全画面表示か否か
   */
  showLoading: () => void;

  /**
   * loading非表示
   */
  hideLoading: () => void;

  isNeedCon: (db: IDbClient) => boolean
  isPwa: () => boolean
  
}


export class BasicUtil implements IBasicUtil {

  private loading: ActiveLoader | undefined
  private loadingCount = 0;
  public async showLoading() {
    if (this.loadingCount == 0) {
      const $loading = useLoading()
      this.loading = $loading.show({
        color: '#ccc',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.3,
        zIndex: 999,
      });
    }
    this.loadingCount += 1
  }

  public async hideLoading() {
    this.loadingCount -= 1
    if (this.loadingCount == 0) {
      this.loading?.hide()
    }
  }

  public isNeedCon (db: IDbClient) {
    if (navigator.onLine) return true
    // 通信不能の場合
    const chapter = db.getChapter(1);
    if (chapter == null) return true
    return false
  } 
 
  public isPwa() {
    if ((window.navigator as any).standalone) {
      return true
    }
    else if (matchMedia('(display-mode: standalone)').matches) {
      return true
    } else {
      return false
    }
  }

}