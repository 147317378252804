import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { DefaultHttpClient, HttpClient, IHttpClient } from './services/HttpClient';
import { BasicUtil, DefaultBasicUtil, IBasicUtil } from './services/BasicUtil';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import { DbClient, DefaultDbClient, IDbClient } from './services/DbClient';
import { DefaultRouteState, IRouteState, RouteState } from './services/RouteState';
import { DefaultSessionState, ISessionState, SessionState } from './services/SessionState';

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';


const app = createApp(App)
  .use(router)
  .use(VueLoading)
  .use(ToastPlugin);

const basicUtil = new BasicUtil()
app.provide<IBasicUtil>(DefaultBasicUtil, basicUtil);

app.provide<IHttpClient>(DefaultHttpClient, new HttpClient(basicUtil));
app.provide<IDbClient>(DefaultDbClient, new DbClient());
app.provide<IRouteState>(DefaultRouteState, new RouteState());
app.provide<ISessionState>(DefaultSessionState, new SessionState());


app.mount("#app");
