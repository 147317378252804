import { App, InjectionKey } from "vue";
import { AppDb, IChapter, IConfig, IDayData, IQuestion, IQuestionResult, IResult, ISection, QuestionSet, SearchQuery } from './database';
export const DefaultDbClient: InjectionKey<IDbClient> = Symbol("db");
export interface IDbClient {
  getConfig: () => Promise<IConfig | null>
  addConfig: (data: IConfig) => Promise<void>;
  deleteQuestionAndResult: (questionId: number) => Promise<void>;
  updateConfig: (data: IConfig) => Promise<void>;
  getChapters: () => Promise<IChapter[]>
  getSections: () => Promise<ISection[]>
  getChapter: (id: number) => Promise<IChapter | null>
  addQuestionSet: (chapter: IChapter[], section: ISection[], question: IQuestion[]) => Promise<void>
  updateQuestionSet: (chapter: IChapter[], section: ISection[], question: IQuestion[]) => Promise<void>
  saveQuestionResult: (d: IQuestionResult) => Promise<void>
  setQuestionResult: (question: IQuestion[]) => Promise<void>
  saveResult: (result: IResult) => Promise<void>
  setDayData: () => Promise<void>
  getDayData: (date: string) => Promise<IDayData>
  getQuestionSets: (query: SearchQuery) => Promise<QuestionSet[]>
  getSearchQuery: () => Promise<SearchQuery | null>
  setSearchQuery: (q: SearchQuery) => Promise<void>
  getAllProgressData: () => Promise<number[]>
  getQuestionResults: () => Promise<IQuestionResult[]>
  getReviewDatas: (type: number) => Promise<IQuestionResult[]>
  getReviewQuestionSets: (d: IQuestionResult[]) => Promise<QuestionSet[]>
  getCorrectPer: (limit: number) => Promise<IResult[]>
  deleteAllDb: () => Promise<boolean>
}

export class DbClient implements IDbClient {
  private _db: AppDb;
  constructor() {
    this._db = new AppDb()
  }

  private fullDiskAlert(err: any) {
    if (err.name === 'QuotaExceededError') {
      alert('データ保存空き容量が少ないのでアプリをご利用いただくことができません。不要なファイルを削除してからご利用ください。');
    }
  }

  public async addConfig(data: IConfig) {
    try {
      data.id = 1
      await this._db.config.add(data);
    } catch (err: any) {
      this.fullDiskAlert(err)
    }
  }

  public async updateConfig(data: IConfig) {
    try {
      await this._db.config.update(data.id!, data);
    } catch (err: any) {
      this.fullDiskAlert(err)
    }
  }

  public async getConfig() {
    const data = await this._db.config.get(1);
    if (data == undefined) return null
    return data
  }

  public async getChapters() {
    // return await this._db.chapter.where('name').equals('テスト').toArray();
    return await this._db.chapter.toArray();
  }

  public async getSections() {
    return await this._db.section.toArray();
  }

  public async getChapter(id: number) {
    return await this._db.chapter.get(id) ?? null;
  }

  public async getAllProgressData() {
    const data = await this._db.questionResult.toArray()
    const allCount = data.length
    const allCorrectCount = data.filter(d => {
      return d.correctCount > 0
    }).length
    if (allCount == 0) return [0, 0, 0, 0, 0]

    return [
      allCorrectCount / allCount,
      this.getChapterProgress(1, data),
      this.getChapterProgress(2, data),
      this.getChapterProgress(3, data),
      this.getChapterProgress(4, data),
    ]
  }

  private getChapterProgress(chapterId: number, data: IQuestionResult[]) {
    const d = data.filter(d => {
      return d.chapterId == chapterId
    })
    if (d.length == 0) return 0

    const correctCount = d.filter(d2 => {
      return d2.correctCount > 0
    }).length

    return correctCount / d.length
  }

  public async addQuestionSet(chapter: IChapter[], section: ISection[], question: IQuestion[]) {
    try {

      await this._db.chapter.bulkAdd(chapter);
      await this._db.section.bulkAdd(section);
      await this._db.question.bulkAdd(question);
    } catch (err: any) {
      this.fullDiskAlert(err)
    }
  }

  public async updateQuestionSet(chapter: IChapter[], section: ISection[], question: IQuestion[]) {
    try {

      if (chapter.length > 0) await this._db.chapter.bulkPut(chapter);
      if (section.length > 0) await this._db.section.bulkPut(section);
      if (question.length > 0) await this._db.question.bulkPut(question);
    } catch (err: any) {
      this.fullDiskAlert(err)
    }
  }

  public async deleteQuestionAndResult(questionId: number) {
    try {
      await this._db.question.delete(questionId);
      const qr = await this._db.questionResult.where('questionId').equals(questionId).first()
      if (qr) await this._db.questionResult.delete(qr!.id!);
    } catch (err: any) {
      this.fullDiskAlert(err)
    }
  }

  public async getSearchQuery() {
    return await this._db.searchQuery.get(1) ?? null;
  }

  public async setSearchQuery(q: SearchQuery) {
    const data = JSON.parse(JSON.stringify(q))
    try {
      data.id = 1
      await this._db.searchQuery.put(data);
    } catch (err: any) {
      this.fullDiskAlert(err)
    }
  }

  public async saveQuestionResult(d: IQuestionResult) {
    try {
      await this._db.questionResult.put(d);
    } catch (err: any) {
      this.fullDiskAlert(err)
    }
  }

  public async saveResult(d: IResult) {
    try {
      await this._db.result.put(d);
    } catch (err: any) {
      this.fullDiskAlert(err)
    }
  }

  public async setQuestionResult(question: IQuestion[]) {
    if (question.length == 0) return
    try {
      const old = await this._db.questionResult.toArray();
      for (let i = 0; i < question.length; i++) {
        const d = question[i]
        const res = old.find(o => {
          return o.questionId == d.id
        })
        if (!res) {
          await this._db.questionResult.add({
            chapterId: d.chapterId,
            sectionId: d.sectionId,
            questionId: d.id!,
            allCount: 0,
            correctCount: 0,
            isBookmark: false,
            history: "",
            reviewHistory: "",
            historyTime: ""
          })
        }
      }

    } catch (err: any) {
      this.fullDiskAlert(err)
    }
  }

  public async getDayData(date: string) {
    return await this._db.dayData.where('dateStr').equals(date).first() ?? { dateStr: date, count: 0 };
  }

  public async setDayData() {
    var date = new Date();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var formattedDate = (month < 10 ? '0' : '') + month + '/' + (day < 10 ? '0' : '') + day;

    let data = await this._db.dayData.where('dateStr').equals(formattedDate).first()
    if (data == undefined) {
      data = {
        dateStr: formattedDate,
        count: 0
      }
    }
    data.count += 1

    try {
      await this._db.dayData.put(data);
    } catch (err: any) {
      this.fullDiskAlert(err)
    }
  }


  public async getQuestionSets(query: SearchQuery) {
    const chaptersTask = this._db.chapter.toArray()
    const sectionsTask = this._db.section.toArray();


    let questionsQuery = null
    if (query.chapterId > 0) {
      questionsQuery = this._db.question.where('chapterId').equals(query.chapterId)
    }
    if (query.sectionId > 0) {
      if (questionsQuery == null) {
        questionsQuery = this._db.question.where('sectionId').equals(query.sectionId)
      } else {
        questionsQuery = questionsQuery.and(q => q.sectionId == query.sectionId)
      }
    }
    let questionsTask = null
    if (questionsQuery == null) {
      questionsTask = this._db.question.toArray()
    } else {
      questionsTask = questionsQuery.toArray()
    }

    let questionRsQuery = null
    if (query.chapterId > 0) {
      questionRsQuery = this._db.questionResult.where('chapterId').equals(query.chapterId)
    }
    if (query.sectionId > 0) {
      if (questionRsQuery == null) {
        questionRsQuery = this._db.questionResult.where('sectionId').equals(query.sectionId)
      } else {
        questionRsQuery = questionRsQuery.and(q => q.sectionId == query.sectionId)
      }
    }
    let questionResultsTask = null
    if (questionRsQuery == null) {
      questionResultsTask = this._db.questionResult.toArray()
    } else {
      questionResultsTask = questionRsQuery.toArray()
    }


    Promise.all([chaptersTask, sectionsTask, questionsTask, questionResultsTask])
    const qusetions = await questionsTask
    const questionResults = await questionResultsTask
    const chapters = await chaptersTask
    const sections = await sectionsTask
    const ret: QuestionSet[] = []
    for (let i = 0; i < qusetions.length; i++) {
      const qusetion = qusetions[i]
      const questionResult = questionResults.find(q => {
        return q.questionId == qusetion.id
      })
      const chapter = chapters.find(q => {
        return q.id == qusetion.chapterId
      })
      const section = sections.find(q => {
        return q.id == qusetion.sectionId
      })
      ret.push({
        question: qusetion,
        chapter: chapter!,
        section: section!,
        questionResult: questionResult!,
        result: {
          questionId: qusetion.id!,
          isAnswer: false,
          isCorrect: false,
          choice: 0
        }
      })
    }

    return ret
  }

  public async getReviewQuestionSets(questionResults: IQuestionResult[]) {
    const chaptersTask = this._db.chapter.toArray()
    const sectionsTask = this._db.section.toArray();

    let questionIds: number[] = []
    questionResults.forEach(q => {
      questionIds.push(q.questionId)
    })

    let questionsTask = this._db.question.where("id").anyOf(questionIds).toArray()


    Promise.all([chaptersTask, sectionsTask, questionsTask])
    const qusetions = await questionsTask
    const chapters = await chaptersTask
    const sections = await sectionsTask
    const ret: QuestionSet[] = []
    for (let i = 0; i < qusetions.length; i++) {
      const qusetion = qusetions[i]
      const questionResult = questionResults.find(q => {
        return q.questionId == qusetion.id
      })
      const chapter = chapters.find(q => {
        return q.id == qusetion.chapterId
      })
      const section = sections.find(q => {
        return q.id == qusetion.sectionId
      })
      ret.push({
        question: qusetion,
        chapter: chapter!,
        section: section!,
        questionResult: questionResult!,
        result: {
          questionId: qusetion.id!,
          isAnswer: false,
          isCorrect: false,
          choice: 0
        }
      })
    }

    return ret
  }

  public getQuestionResults() {
    return this._db.questionResult.toArray()
  }

  public async getReviewDatas(type: number) {
    const d = await this.getQuestionResults()
    return d.filter(a => {
      return a.reviewHistory.slice(-1) == String(type)
    })
  }

  public async getCorrectPer(limit: number) {
    return this._db.result.orderBy('id').reverse().limit(limit).toArray()
  }

  public async deleteAllDb() {
    return this._db.delete().then(function () {
      return true
    }).catch(function (err) {
      return false
    });
  }
}