<template>
  <div class="main-container">
    <Header />
    <main class="body-basic2">
      <router-view :key="$route.fullPath" />
    </main>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from 'vue';
import Header from "./components/Header.vue"
export default defineComponent({
  name: 'App',
  components: {
    Header
  },
})
</script>
<style>
body {
  overscroll-behavior-y: none;
}

html,
body {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
}


img {
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-touch-callout: none;
  -moz-user-select: none;
  user-select: none;
}

.body-basic {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.btn-main {
  background: #00B4C3 !important;
  background-color: #00B4C3 !important;
  border-color: #00B4C3 !important;
  color: white !important;
  font-weight: bold;
  font-size: 15px;
  min-width: 100px;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.btn-main:active {
  opacity: 0.5;
  color: white !important;
}

.btn-dark {
  color: #fff;
  transition: none !important;
}

.btn-dark:active {
  background: #000000;
  opacity: 0.7;
}

.body-basic2 {
  position: absolute;
  top: 56px;
  left: 0;
  width: 100%;
  height: calc(100vh - 56px);
  overflow-y: scroll;
  overflow-x: hidden !important;
}
.mw {
  max-width: 650px;
  margin: auto;
}
</style>
