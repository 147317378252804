import { InjectionKey, reactive } from "vue";
import { QuestionSet } from "./database";

export const DefaultSessionState: InjectionKey<ISessionState> = Symbol("session-state");
export interface ISessionState {
    questionSets: QuestionSet[],
    startAt: number
}

const state = reactive<ISessionState>({
    questionSets: [],
    startAt: 0
});

export class SessionState implements ISessionState {
    get questionSets() {
        return state.questionSets;
    }

    set questionSets(value: QuestionSet[]) {
        state.questionSets = value;
    }

    get startAt() {
        return state.startAt;
    }

    set startAt(value: number) {
        state.startAt = value;
    }
}