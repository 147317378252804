import axios, { AxiosInstance, AxiosResponse } from "axios";
import Cookies from 'js-cookie';
import { InjectionKey } from "vue";
import { IBasicUtil } from "./BasicUtil";

export const DefaultHttpClient: InjectionKey<IHttpClient> = Symbol("api");
export interface IHttpClient {
  login: (data: IAuthRequest) => Promise<IAuthResponse>;
  get: (url: string) => Promise<any>
  auth: () => Promise<IAuthResponse>;
  apiLogin: (data: IApiLoginRequest) => Promise<IApiLoginResponse|null>
  setLoading: (component: any, isFullScreen: boolean, isSetLoading: boolean) => IHttpClient
  postResult: (data: IResultRequest) => Promise<void>
  reset: () => Promise<void>
}

export interface IAuthResponse {
  result: boolean,
  cookie: string
}

export interface IAuthRequest {
  userId: string
  password: string
}

export interface IApiLoginRequest {
  version: number
}

export interface IApiLoginResponse {
  chapterUrl: string
  sectionUrl: string
  questionUrl: string
  latestVersion: number
  deleteIds: number[]
}

export interface IResultRequest {
  id: number;
  isCorrect: boolean;
  allProgress: number;
  progress1: number;
  progress2: number;
  progress3: number;
  progress4: number;
}

export class HttpClient implements IHttpClient {
  public component: any = null
  public isFullScreen = true
  public isSetLoading = true
  private axiosInstance: AxiosInstance
  private basicUtil: IBasicUtil

  constructor(basicUtil: IBasicUtil) {
    this.basicUtil = basicUtil
    this.axiosInstance = this.getBasicInstance()
  }

  private getBasicInstance(): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: true,
    });
    axiosInstance.interceptors.request.use((request) => {
      this.basicUtil.showLoading()
      return request;
    });
    axiosInstance.interceptors.response.use(
      (response) => {
        this.basicUtil.hideLoading()
        return response;
      },
      (error) => {
        this.basicUtil.hideLoading()
        if (error.response.status == 401 && window.location.pathname != '/auth') {
          Cookies.remove("t-token")
          location.href = "/auth"
        }
        throw error;
      }
    );
    return axiosInstance
  }

  public setLoading(component: any, isFullScreen: boolean = false, isSetLoading: boolean = true) {
    this.component = component
    this.isFullScreen = isFullScreen
    this.isSetLoading = isSetLoading
    return this
  }

  private setAuthHeader() {
    this.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get("t-token");
  }

  public async get(url: string) {
    return axios.get(url).then((res: AxiosResponse<any>) => {
      return res.data
    }).catch((_) => {
      return null
    })
  }

  public async auth() {
    this.setAuthHeader()
    return this.axiosInstance.get<IAuthResponse>("auth").then((res: AxiosResponse<IAuthResponse>) => {
      return res.data
    }).catch((_) => {
      return {result: false, cookie: ""}
    })
  }

  public async login(data: IAuthRequest) {
    return this.axiosInstance.post<IAuthResponse>("auth/login", data).then((res: AxiosResponse<IAuthResponse>) => {
      return res.data
    }).catch((_) => {
      return {result: false, cookie: ""}
    })
  }

  public async apiLogin(data: IApiLoginRequest) {
    this.setAuthHeader()
    return this.axiosInstance.post<IApiLoginResponse>("auth/api-login", data).then((res: AxiosResponse<IApiLoginResponse>) => {
      return res.data
    }).catch((_) => {
      return null
    })
  }

  public async reset() {
    this.setAuthHeader()
    return this.axiosInstance.post("auth/reset", null).then((res: AxiosResponse<void>) => {
      return 
    }).catch((_) => {
      return 
    })
  }

  public async postResult(data: IResultRequest) {
    try {
      if (!navigator.onLine) return
      const axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: true,
      });
      axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get("t-token");
      axiosInstance.post("result", data).catch(_ => {
        
      })
    } catch {

    }
    
  }
}